export default [
  {
    name: "Esra ÇUHADAR",
    email: "",
    title: "",
    src:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people/ESRA-WEB.png",
    mobile:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people-mobile/ESRA-MOBIL.png",
    size: 4,
  },
  {
    name: "Dr. M. Attila SEVİM",
    email: "mas@manastir.co",
    title: "CO-FOUNDER",
    src: "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people/MAS-1.jpg",
    mobile:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people-mobile/MAS.jpg",
    size: 4,
  },
  {
    name: "Elif ÇİLİNGİR",
    email: "elif@manastir.co",
    src:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people/elif-c.jpg",
    mobile:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people-mobile/elif-c.jpg",
    title: "INTERACTIVE, DEVELOPMENT",
    size: 4,
  },
  {
    name: "Orhan KUYUMCU",
    email: "orhan@manastir.co",
    src:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people/orhan-k.jpg",
    mobile:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people-mobile/orhan-k.jpg",
    title: "COMMUNICATIONS, DESIGN",
    size: 4,
  },
  {
    name: "Özlem ERTEN",
    email: "",
    title: "",
    src:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people/OZLEM-WEB.png",
    mobile:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people-mobile/OZLEM-MOBIL.png",
    size: 4,
  },
  {
    name: "Mesut ÖZER",
    email: "mesut@manastir.co",
    title: "DEPUTY GENERAL MANAGER",
    src:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people/MESUT-OZER-WEB.png",
    mobile:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people-mobile/Mesut-ozer.jpg",
    size: 4,
  },
  {
    name: "Mustafa Tuğrul SEVİM",
    email: "",
    title: "",
    src:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people/MUSTAFA-WEB.png",
    mobile:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people-mobile/MUSTAFA-MOBIL.png",
    size: 4,
  },
  {
    name: "Seyyid Fatih KOÇ",
    email: "fatih@manastir.co",
    src: "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people/fatih.jpg",
    mobile:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people-mobile/fatih.jpg",
    title: "INTERACTIVE, DEVELOPMENT",
    size: 4,
  },
  {
    name: "Jale SUBAŞ",
    email: "",
    title: "",
    src:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people/JALE-WEB-02.png",
    mobile:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people-mobile/JALE-MOBIL-02.png",
    size: 4,
  },
  {
    name: "İbrahim TELMAN",
    email: "ibrahim@manastir.co",
    src:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people/ibrahim.jpg",
    mobile:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people-mobile/ibrahim.jpg",
    title: "INTERACTIVE, DEVELOPMENT",
    size: 4,
  },
  {
    name: "Özge KIRAÇ",
    email: "ozge@manastir.co",
    src: "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people/ozge.jpg",
    mobile:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people-mobile/ozge.jpg",
    title: "EXPERIENCE, EVENT MANAGEMENT",
    size: 4,
  },
  {
    name: "Kemal GÜMÜŞTABAK",
    email: "kemal@manastir.co",
    title: "EXPERIENCE, BUSINESS DEVELOPMENT",
    src:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people/kemal-g.jpg",
    mobile:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people-mobile/kemal-g.jpg",
    size: 4,
  },
  {
    name: "Çağla PINARBAŞI",
    email: "cagla@manastir.co",
    src: "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people/cagla.jpg",
    mobile:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people-mobile/cagla.jpg",
    title: "EXPERIENCE, BUSINESS DEVELOPMENT",
    size: 4,
  },
  {
    name: "Gökberk SERTKAYA",
    email: "gokberk@manastir.co",
    src:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people/gokberk.jpg",
    mobile:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people-mobile/gokberk.jpg",
    title: "COMMUNICATIONS, MARKETING",
    size: 4,
  },
  {
    name: "Ceren KOLOĞLU",
    email: "",
    title: "",
    src:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people/CEREN-WEB.png",
    mobile:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people-mobile/CEREN-MOBIL.png",
    size: 4,
  },
  {
    name: "Orhun Ceng BOZO",
    email: "ocb@manastir.co",
    title: "DEPUTY GENERAL MANAGER",
    src:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people/OCB-WEB.png",
    mobile:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people-mobile/ocb.jpg",
    size: 4,
  },
  {
    name: "Gökçe Zeynep YILDIZ",
    email: "",
    title: "",
    src:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people/GZY-WEB-02.png",
    mobile:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people-mobile/GZY-MOBIL-02.png",
    size: 4,
  },
  {
    name: "Doğan Can ERGÜN",
    email: "dogan@manastir.co",
    title: "DEPUTY GENERAL MANAGER",
    src:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people/DCE-WEB.png",
    mobile:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people-mobile/dce.jpg",
    size: 4,
  },
  {
    name: "Aslıhan VURAL",
    email: "aslihan@manastir.co",
    title: "ART DIRECTOR",
    src:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people/aslihan-vural.jpg",
    mobile:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people-mobile/aslihan-vural.jpg",
    size: 4,
  },
  {
    name: "Ceren ŞAHİN",
    email: "ceren@manastir.co",
    src:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people/ceren-s.jpg",
    mobile:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people-mobile/ceren-s.jpg",
    title: "EXPERIENCE, EVENT MANAGEMENT",
    size: 4,
  },
  {
    name: "Aynil KOÇAK",
    email: "aynil@manastir.co",
    src:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people/aynil-kocak.jpg",
    mobile:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people-mobile/aynil-kocak.jpg",
    title: "CUSTOMER RELATIONS MANAGER",
    size: 4,
  },
  {
    name: "Derya Nur ERDEM",
    email: "",
    src: "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people/derya.jpg",
    mobile:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people-mobile/derya.jpg",
    title: "ACCOUNTING",
    size: 4,
  },
  {
    name: "Emre PARLAK",
    email: "",
    src: "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people/emre.jpg",
    mobile:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people-mobile/emre.jpg",
    title: "HUMAN RESOURCES",
    size: 4,
  },
  {
    name: "Oscar",
    email: "",
    src: "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people/oscr.jpg",
    mobile:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people-mobile/oscr.jpg",
    title: "",
    size: 4,
  },
  {
    name: "Cemal ZOBİ",
    email: "",
    src: "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people/cemal.jpg",
    mobile:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people-mobile/cemal.jpg",
    title: "PURCHASING",
    size: 4,
  },
  {
    name: "Betül Büşra BABALIKLI",
    email: "",
    title: "",
    src:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people/BETUL-WEB-02.png",
    mobile:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people-mobile/BETUL-MOBIL-02.png",
    size: 4,
  },
  {
    name: "Mesut SARI",
    email: "mesut.s@manastir.co",
    src:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people/mesut-s.jpg",
    mobile:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people-mobile/mesut-s.jpg",
    title: "COMMUNICATIONS, DESIGN",
    size: 4,
  },
  {
    name: "Selinay DEMET",
    email: "",
    title: "",
    src:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people/SELINAY-WEB-02.png",
    mobile:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people-mobile/SELINAY-MOBIL-02.png",
    size: 4,
  },
  {
    name: "Selin DEMİRDOĞAN",
    email: "selin@manastir.co",
    title: "PARTNERSHIP, SALES",
    src:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people/selin-demirdogan.jpg",
    mobile:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people-mobile/selin-demirdogan.jpg",
    size: 4,
  },
  {
    name: "Elif Şuheda SEVİM",
    email: "",
    title: "",
    src:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people/ELIF-SUHEDA-WEB.png",
    mobile:
      "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/people-mobile/ELIF-SUHEDA-MOBIL.png",
    size: 4,
  },
]
