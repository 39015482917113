import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/Footer"
import data from "../data/people"
const People = () => {
  return (
    <Layout>
      <SEO title="People" />
      <h1 className={"hidden"}>People</h1>
      <div className="row">
        {data.map((person, i) => (
          <div key={i} className={`${["col-" + person.size]} col-sm`}>
            <div className="people">
              <picture>
                <source media={"(max-width: 768px)"} srcSet={person.mobile} />
                <img src={person.src} alt={person.name} />
              </picture>
              <div className="overlay">
                <div className="overlay_info">
                  <div className="people_name">{person.name} </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </Layout>
  )
}

export default People
